<template>
  <div class="tab-content" id="PTtab-6" :key="'PTtab-6'" v-if="commonCodeByOrder">
    <div>
      <div class="pagenamPT">
        <h3 class="pagename2">베팅내역</h3>
      </div>
      <div class="sch_PC">
        <div class="PTsch flex-c">
          <div class="datesearchPTWarp flex-c gap-2">
            <h4>날짜</h4>
            <date-filter :retail="true" @search="loadData(1)"
                         @update="onChangeDateTable"
                         :defaultDay="0"
                         :startDate="mainTableDate.startDate"
                         :endDate="mainTableDate.endDate"
                         :isOldYn="true"
                         :id="'main-date-checkbox1'"
                         @setOldYn="setOldYn" :oldYn="reqData.oldYn" />
          </div>
        </div>
        <div class="sch_Btm">
          <div class="searchPTwrap">
            <div class="searchPT">
              <div class="datesearchPT flex-c gap-2">
                <h4>게임구분</h4>
                <select v-model="reqData.gameCategory" class="h36px">
                  <option value="casino" selected>카지노</option>
                  <option value="slot">슬롯</option>
                  <option value="sport">스포츠</option>
                  <option value="minigame">미니게임</option>
                </select>
              </div>
              <div class="datesearchPT flex-c gap-2">
                <h4>게임사</h4>
                <select v-model="reqData.vendorCode" class="h36px">
                  <option value="" selected>전체보기</option>
                  <template v-for="(item, code) in commonCodeByOrder[reqData.gameCategory]" :key="code">
                    <option :value="item.code">{{item.codeName}}</option>
                  </template>
                </select>
              </div>
              <div class="datesearchPT flex-c gap-2">
                <h4>상태</h4>
                <select v-model="reqData.resultType" class="h36px">
                  <option value="" selected>전체</option>
                  <option value="win" selected>승</option>
                  <option value="lose">패</option>
                  <option value="draw">무</option>
                  <option value="wait">대기</option>
                  <option value="cancel">취소</option>
                </select>
              </div>
              <div class="datesearchPT flex-c gap-2">
                <h4>구분</h4>
                <select class="h36px" v-model="orderColumn">
                  <option value="searchMemId" selected>아이디</option>
                  <option value="memNick">닉네임</option>
                  <option value="recommenderId">상위유저</option>
                </select>
              </div>
            </div>
            <div class="searchPT">
              <input type="text" :placeholder="$t('front.search.emptySearch')" v-model="searchText" class="h36px"/>
              <a @click="loadData(1)" class="sch_Icon">
                <img src="@/assets/img/search.png" alt=""/>
              </a>
            </div>
          </div>
          <div class="datesearchPT flex-c gap-2 w30p`">
            <h4>정렬</h4>
            <select v-model="orderStr" class="h36px">
              <option value="betAmt_DESC">베팅금 많은순</option>
              <option value="betAmt_ASC">베팅금 적은순</option>
              <option value="betWinAmt_DESC">당첨금 많은순</option>
              <option value="betWinAmt_ASC">당첨금 적은순</option>
              <option value="regDate_DESC">배팅시각 최신순</option>
              <option value="regDate_ASC">배팅시각 오래된순</option>
            </select>
          </div>
        </div>
      </div>
      <div class="sch_M">
        <div class="PTsch flex-c">
          <div class="datesearchM">
            <date-filter-mobile :retail="true" @search="loadData(1)"
                        @update="onChangeDateTable"
                        :defaultDay="0"
                        :startDate="mainTableDate.startDate"
                        :endDate="mainTableDate.endDate"
                        :isOldYn="true"
                        :id="'main-date-checkbox1'"
                        @setOldYn="setOldYn" :oldYn="reqData.oldYn"
              />
          </div>
        </div>
        <div class="sch_Btm">
          <div class="searchPTwrap">
            <div class="searchPT">
              <div class="datesearchPT flex-c gap-2">
                <h4>게임구분</h4>
                <select v-model="reqData.gameCategory" class="h36px">
                  <option value="casino" selected>카지노</option>
                  <option value="slot">슬롯</option>
                  <option value="sport">스포츠</option>
                  <option value="minigame">미니게임</option>
                </select>
              </div>
              <div class="datesearchPT flex-c gap-2">
                <h4>게임사</h4>
                <select v-model="reqData.vendorCode" class="h36px">
                  <option value="" selected>전체보기</option>
                  <template v-for="(item, code) in commonCodeByOrder[reqData.gameCategory]" :key="code">
                    <option :value="item.code">{{item.codeName}}</option>
                  </template>
                </select>
              </div>
              <div class="datesearchPT flex-c gap-2">
                <h4>상태</h4>
                <select v-model="reqData.resultType" class="h36px">
                  <option value="" selected>전체</option>
                  <option value="win" selected>승</option>
                  <option value="lose">패</option>
                  <option value="draw">무</option>
                  <option value="wait">대기</option>
                  <option value="cancel">취소</option>
                </select>
              </div>
            </div>
          </div>
          <div class="searchPTwrap">
            <div class="searchPT w30p">
              <div class="datesearchPT flex-c gap-2">
                <h4>구분</h4>
                <select class="h36px" v-model="orderColumn">
                  <option value="searchMemId" selected>아이디</option>
                  <option value="memNick">닉네임</option>
                  <option value="recommenderId">상위유저</option>
                </select>
              </div>
            </div>
            <div class="searchPT">
              <input type="text" :placeholder="$t('front.search.emptySearch')" v-model="searchText" class="h36px"/>
              <a @click="loadData(1)" class="sch_Icon">
                <img src="@/assets/img/search.png" alt=""/>
              </a>
            </div>
          </div>
          <div class="searchPTwrap">
            <div class="datesearchPT flex-c gap-2 w50p">
              <h4>정렬</h4>
              <select v-model="orderStr" class="h36px">
                <option value="betAmt_DESC">베팅금 많은순</option>
                <option value="betAmt_ASC">베팅금 적은순</option>
                <option value="betWinAmt_DESC">당첨금 많은순</option>
                <option value="betWinAmt_ASC">당첨금 적은순</option>
                <option value="regDate_DESC">배팅시각 최신순</option>
                <option value="regDate_ASC">배팅시각 오래된순</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="strTablescr">
         <div class="strTablePC">
            <table class="strTablePT">
              <colgroup>
              </colgroup>
              <thead>
                <tr>
                  <th>번호</th>
                  <th>아이디</th>
                  <th>닉네임</th>
                  <th>게임구분</th>
                  <th>게임사</th>
                  <th>게임명</th>
                  <th>처리 전</th>
                  <th>{{ $t('front.stributor.m22') }}</th>
                  <th>{{ $t('front.stributor.m23') }}</th>
                  <th>윈루즈</th>
                  <th>처리 후</th>
                  <th>베팅시각</th>
                  <th>상태</th>
                </tr>
                <!-- <tr>
                  <th>번호</th>
                  <th>아이디</th>
                  <th>게임 종류</th>
                  <th>게임구분</th>
                  <th>베팅날짜</th>
                  <th>처리 전 게임머니</th>
                  <th>{{ $t('front.stributor.m22') }}</th>
                  <th>{{ $t('front.stributor.m23') }}</th>
                  <th>윈루즈</th>
                  <th>처리 후 게임머니</th>
                  <th>상태</th>
                </tr> -->
              </thead>
              <tbody>
                <template v-if="bettingList.length > 0">
                  <template v-for="item in bettingList" :key="item.betIdx">
                    <tr>
                      <td>{{item.betIdx}}</td>
                      <td>{{item.memId}}</td>
                      <td class="nick">{{item.memNick}}</td>
                      <td>{{item.gameCategory}}</td>
                      <td>{{item.vendorName}}</td>
                      <td>{{item.gameName}}</td>
                      <td>{{thousand(item.beforeCash)}}</td>
                      <td>{{thousand(item.cash)}}</td>
                      <td>{{thousand(item.winCash)}}</td>
                      <td>
                        <div :class="{ 'rdc' : item.winCash - item.cash > 0, 'blc' : item.winCash - item.cash < 0}">
                          {{ thousand(Number(item.winCash) - Number(item.cash)) }}
                        </div>
                      </td>
                      <td>{{thousand(item.afterCash)}}</td>
                      <td>{{dateFormatAll(item.createdAt)}}</td>
                      <td>{{typeName(item.type)}}</td>
                    </tr>
                  </template>
                </template>
                <template v-else>
                  <tr>
                  </tr>
                </template>
              </tbody>
            </table>
         </div>
         <div class="strTableM">
            <div class="strTablePTM">
               <template v-if="bettingList.length > 0">
                 <template v-for="item in bettingList" :key="item.betIdx">
                  <ul>
                    <li>
                      <em>번호</em>
                      <div><span>{{item.betIdx}}</span></div>
                    </li>
                    <li>
                      <em>처리 전</em>
                      <div><span>{{thousand(item.beforeCash)}}</span></div>
                    </li>
                    <li>
                      <em>아이디</em>
                      <div><span>{{item.memId}}</span></div>
                    </li>
                    <li>
                      <em>{{ $t('front.stributor.m22') }}</em>
                      <div><span>{{thousand(item.cash)}}</span></div>
                    </li>
                    <li>
                      <em>닉네임</em>
                      <div><span class="nick">{{item.memNick}}</span></div>
                    </li>
                    <li>
                      <em>{{ $t('front.stributor.m23') }}</em>
                      <div><span>{{thousand(item.winCash)}}</span></div>
                    </li>
                    <li>
                      <em>게임구분</em>
                      <div><span>{{item.gameName}}</span></div>
                    </li>
                    <li>
                      <em>윈루즈</em>
                      <div><span :class="{ 'rdc' : item.winCash - item.cash > 0, 'blc' : item.winCash - item.cash < 0}">{{ thousand(Number(item.winCash) - Number(item.cash)) }}</span></div>
                    </li>
                    <li>
                      <em>게임사</em>
                      <div><span>{{item.vendorName}}</span></div>
                    </li>
                    <li>
                      <em>처리 후</em>
                      <div><span>{{thousand(item.afterCash)}}</span></div>
                    </li>
                    <li>
                      <em>게임명</em>
                      <div><span>{{item.gameName}}</span></div>
                    </li>
                    <li>
                      <em>베팅 시각</em>
                      <div><span>{{dateFormatAll(item.createdAt)}}</span></div>
                    </li>
                    <li>
                      <em>상태</em>
                      <div><span>{{typeName(item.type)}}</span></div>
                    </li>
                    <li>
                      <em></em>
                      <div><span></span></div>
                    </li>
                     <!-- <li><em>게임 종류</em>{{item.vendorName}}</li>
                     <li><em>게임구분</em>{{item.gameName}}</li>
                     <li><em>베팅날짜</em>{{dateFormatAll(item.createdAt)}}</li>
                     <li><em>처리 전<br />게임머니</em></li>
                     <li><em>윈루즈</em>{{ thousand(Number(item.winCash) - Number(item.cash)) }}</li>
                     <li><em>처리 후<br />게임머니</em>{{thousand(item.afterCash)}}</li>
                     <li><em>상태</em>{{typeName(item.type)}}</li>-->
                   </ul>
                 </template>
               </template>
               <template v-else>
                 <ul>
                    <li class="nodata">내역 없음</li>
                 </ul>
               </template>
            </div>
         </div>
      </div>
      <pagination v-if="pageInfo"
                  :pageNum="pageInfo.page"
                  :pageSize="pageInfo.count_per_list"
                  :totalCount="pageInfo.tatal_list_count"
                  :className="'partnerPaging'"
                  @goToPage="loadData"
      />
    </div>
  </div>
</template>

<script>
import DateFilter from '@/components/ui/DateFilter'
import DateFilterMobile from '@/components/ui/DateFilterMobile'
import Pagination from '@/components/ui/Pagination.vue'
import { getDateStr, thousand } from '@/libs/utils'
import { addDays } from 'date-fns'
import { mapState } from 'vuex'
import { getBottomBettingList, partnerLevels } from '@/api/retail'
export default {
  name: 'PartnerBetting',
  components: { DateFilter, DateFilterMobile, Pagination },
  computed: {
    ...mapState([
      'userData',
      'gameCount',
      'commonCodeByOrder',
      'commonCodeByCode'
    ])
  },
  data () {
    return {
      mainTableDate: {
        startDate: getDateStr(addDays(new Date(), 0), 'yyyy-MM-dd 00:00:00'),
        endDate: getDateStr(addDays(new Date(), 0), 'yyyy-MM-dd 23:59:59')
      },
      reqData: {
        gameCategory: 'casino',
        vendorCode: '',
        resultType: '',
        searchMemId: '',
        chkTodayYn: 'N',
        startDate: '',
        endDate: '',
        offset: -1,
        oldYn: 'N'
      },
      orderColumn: 'searchMemId',
      bettingList: [],
      searchText: '',
      orderStr: ''
    }
  },
  async created () {
    this.emitter.emit('Loading', true)
    if (this.$route.query.id) {
      this.orderColumn = 'searchMemId'
      this.searchText = this.$route.query.id
    }
    this.getPartnerLevels()
    this.reqData.startDate = getDateStr(new Date(this.mainTableDate.startDate))
    this.reqData.endDate = getDateStr(new Date(this.mainTableDate.endDate))
    await this.loadData()
    this.emitter.emit('Loading', false)
  },
  watch: {
    orderStr () {
      this.loadData()
    }
  },
  methods: {
    typeName (type) {
      const TYPE_TEXT = {
        win: '승',
        lose: '패',
        draw: '무',
        cancel: '취소',
        wait: '대기'
      }
      return TYPE_TEXT[type]
    },
    thousand,
    setOldYn (data) {
      console.log(data)
      this.reqData.oldYn = data
    },
    getPartnerLevels () {
      partnerLevels({}).then(res => {
        const result = res.data
        if (result.resultCode === '0') {
          this.partnerLevelList = result.data.list
          this.newPartnerLevel = result.data.list[0]

          const partnerObj = {}
          for (let i = 0; i < this.partnerLevelList.length; i++) {
            const item = this.partnerLevelList[i]
            const code = item.code
            const codeName = item.codeName

            if (!partnerObj[code]) {
              partnerObj[code] = codeName
            }
          }

          this.partnerLevelObject = partnerObj
        }
      })
    },
    onChangeDateTable (value) {
      this.reqData.startDate = getDateStr(new Date(value.startDate))
      this.reqData.endDate = getDateStr(new Date(value.endDate))
    },
    async loadData (page) {
      if (!page) {
        page = this.pageInfo.page
      }
      this.emitter.emit('Loading', true)
      if (this.searchText) {
        this.reqData[this.orderColumn] = this.searchText
      }
      const params = { ...this.reqData, page: page, count_per_list: 40 }

      if (this.orderStr) {
        const orderColumn = this.orderStr.split('_')[0]
        const orderType = this.orderStr.split('_')[1]

        params.orderColumn = orderColumn
        params.orderType = orderType
      }

      console.log(params)
      await getBottomBettingList(params).then(res => {
        const data = res.data.data
        if (data) {
          this.bettingList = data.list

          if (data.pageInfo) {
            this.pageInfo = data.pageInfo
          }
        }

        this.emitter.emit('Loading', false)
      })
    }
  }
}
</script>

<style scoped src="@/styles/common.css"></style>
<style scoped src="@/styles/subcommon.css"></style>
<style scoped src="@/styles/striNew.css"></style>

<style scoped>
.PTsch .searchPT h5{line-height: 30px; font-size: 14px;}
#PTpage .tab-content select.selec_w_auto {
  min-width: auto;
}
.strTablePTM li > div{
  text-align: left;
}
@media (max-width: 1000px) {
}
</style>
